import { formatDriverValue } from 'helpers/formatting';
import { Attribute } from 'reduxStore/models/dimensions';
import { DisplayConfiguration } from 'reduxStore/models/value';

/**
 * Renders a tooltip for an AgChart.
 *
 * @param seriesName - The title of the series.
 * @param value - The value to be displayed in the tooltip.
 * @param displayConfiguration - The display configuration for formatting the value.
 * @param attributes - An array of attributes to be appended to the title.
 * @returns An object containing the title and content of the tooltip.
 */
export function renderTooltip(
  seriesName: string | undefined,
  value: number,
  displayConfiguration: DisplayConfiguration,
  attributes?: Attribute[],
) {
  const content = formatDriverValue(value, displayConfiguration, {
    abbreviate: true,
  });

  if (attributes != null && attributes.length > 0) {
    for (const attr of attributes) {
      seriesName += ` (${attr.value})`;
    }
  }

  return {
    title: seriesName,
    content,
  };
}
