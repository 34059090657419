import { deepEqual } from 'fast-equals';
import { fromPairs, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { getHeight, getWidth, SERIES_COLORS } from 'components/AgGridComponents/AgChart/agCharts';
import { ChartDisplay, ChartGroup, ChartSeries } from 'generated/graphql';
import { getMonthKey, getMonthKeysForRange } from 'helpers/dates';
import { applyRollupReducer } from 'helpers/rollups';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import useAppStore from 'hooks/useAppStore';
import { useRequestCellValue } from 'hooks/useRequestCellValue';
import { Attribute } from 'reduxStore/models/dimensions';
import { LayerId } from 'reduxStore/models/layers';
import { DEFAULT_DISPLAY_CONFIGURATION, DisplayConfiguration } from 'reduxStore/models/value';
import { blockConfigViewOptionsSelector } from 'selectors/blocksSelector';
import { entityLoadingAnyMonthInRangeSelector } from 'selectors/calculationsSelector';
import {
  attributesBySubDriverIdSelector,
  driverNamesByIdSelector,
} from 'selectors/driversSelector';
import { driverTimeSeriesForLayerSelector } from 'selectors/driverTimeSeriesSelector';
import { driverDisplayConfigurationSelector } from 'selectors/entityDisplayConfigurationSelector';
import { currentLayerIdSelector, layersSelector } from 'selectors/layerSelector';
import { blockDateRangeDateTimeSelector } from 'selectors/pageDateRangeSelector';
import { driverRollupReducerSelector } from 'selectors/rollupSelector';

function isDisplayConfigurationsEqual(
  a: DisplayConfiguration | undefined,
  b: DisplayConfiguration | undefined,
) {
  return (
    a != null &&
    b != null &&
    a.comparisonType === b.comparisonType &&
    a.currency === b.currency &&
    a.decimalPlaces === b.decimalPlaces &&
    a.format === b.format &&
    a.negativeDisplay === b.negativeDisplay
  );
}

/**
 * Custom hook that returns the configuration for the provided chart display configuration.
 *
 * @param blockId - The ID of the block.
 * @param chartDisplay - The chart display configuration.
 * @returns An object containing the configuration for the chart driver.
 */
export function useChartConfig(blockId: string, chartDisplay: ChartDisplay) {
  const { getState } = useAppStore();
  const driverNamesById = useAppSelector(driverNamesByIdSelector);
  const attributesBySubDriverId = useAppSelector(attributesBySubDriverIdSelector);
  const chartConfig = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));
  const currentLayerId = useAppSelector(currentLayerIdSelector);
  const dateRange = useAppSelector((state) => blockDateRangeDateTimeSelector(state, blockId));

  const width = useMemo(() => getWidth(chartConfig.chartSize), [chartConfig.chartSize]);
  const height = useMemo(() => getHeight(chartConfig.chartSize), [chartConfig.chartSize]);

  const attributesBySeriesId: NullableRecord<string, Attribute[]> = useMemo(() => {
    return fromPairs(
      chartDisplay.series.map((series) => [series.id, attributesBySubDriverId[series.driverId]]),
    );
  }, [chartDisplay.series, attributesBySubDriverId]);

  const seriesNameById: NullableRecord<string, string> = useMemo(() => {
    return fromPairs(
      chartDisplay.series.map((series) => [series.id, driverNamesById[series.driverId]]),
    );
  }, [chartDisplay.series, driverNamesById]);

  const displayConfigurationBySeriesId: NullableRecord<string, DisplayConfiguration> =
    useMemo(() => {
      return fromPairs(
        chartDisplay.series.map((series) => [
          series.id,
          driverDisplayConfigurationSelector(getState(), series.driverId),
        ]),
      );
    }, [chartDisplay.series, getState]);

  const groupDisplayConfigurationById: NullableRecord<string, DisplayConfiguration> =
    useMemo(() => {
      return chartDisplay.groups.reduce(
        (acc, group) => {
          const consensusDisplayConfig = group.seriesIds
            .map((id) => safeObjGet(displayConfigurationBySeriesId[id]))
            .reduce<DisplayConfiguration | undefined>(
              (displayConfiguration, childDisplayConfig) => {
                if (displayConfiguration === undefined) {
                  return childDisplayConfig;
                }
                if (isDisplayConfigurationsEqual(childDisplayConfig, displayConfiguration)) {
                  return childDisplayConfig;
                }
                return DEFAULT_DISPLAY_CONFIGURATION;
              },
              undefined,
            );

          acc[group.id] = consensusDisplayConfig ?? DEFAULT_DISPLAY_CONFIGURATION;

          return acc;
        },
        {} as Record<string, DisplayConfiguration>,
      );
    }, [chartDisplay.groups, displayConfigurationBySeriesId]);

  const totalDisplayConfiguration = useMemo(() => {
    const consensusDisplayConfig = chartDisplay.groups
      .map((group) => groupDisplayConfigurationById[group.id])
      .reduce<DisplayConfiguration | undefined>((displayConfiguration, childDisplayConfig) => {
        if (typeof displayConfiguration === 'undefined') {
          return childDisplayConfig;
        }
        if (isDisplayConfigurationsEqual(childDisplayConfig, displayConfiguration)) {
          return childDisplayConfig;
        }
        return DEFAULT_DISPLAY_CONFIGURATION;
      }, undefined);

    return consensusDisplayConfig ?? DEFAULT_DISPLAY_CONFIGURATION;
  }, [chartDisplay.groups, groupDisplayConfigurationById]);

  const colorBySeriesId: NullableRecord<string, string> = useMemo(() => {
    const colorables = [...chartDisplay.series, ...chartDisplay.groups];
    const colors = colorables.map((colorable) => colorable.color as string | null | undefined);
    const groupIds = chartDisplay.groups.map((g) => g.id);
    const seriesIds = chartDisplay.series.map((s) => s.id);

    return [...seriesIds, ...groupIds].reduce<Record<string, string>>((acc, id, index) => {
      acc[id] = colors[index] ?? SERIES_COLORS[index % SERIES_COLORS.length].value;
      return acc;
    }, {});
  }, [chartDisplay.series, chartDisplay.groups]);

  const groupNamesById: NullableRecord<string, string> = useMemo(() => {
    return chartDisplay.groups.reduce<Record<string, string>>((acc, group, index) => {
      acc[group.id] = group.name ?? `Unnamed Group ${index + 1}`;
      return acc;
    }, {});
  }, [chartDisplay.groups]);

  return {
    attributesBySeriesId,
    chartConfig,
    colorBySeriesId,
    currentLayerId,
    dateRange,
    displayConfigurationBySeriesId,
    groupDisplayConfigurationById,
    groupNamesById,
    height,
    seriesNameById,
    totalDisplayConfiguration,
    width,
  };
}

/**
 * Custom hook for retrieving time series data for a given set of driver IDs and date range.
 *
 * @param dateRange - A tuple containing the start and end date.
 * @param series - An array of chart series.
 * @param groups - An array of chart groups.
 * @returns An object containing the loading status, driver time series data, and attributes by sub-driver ID.
 */
export function useChartTimeSeriesData(
  [start, end]: [DateTime, DateTime],
  series: ChartSeries[],
  groups: ChartGroup[],
) {
  const currentLayerId = useAppSelector(currentLayerIdSelector);
  const layersById = useAppSelector(layersSelector);
  const allLayerIds = useMemo(
    () =>
      uniq([
        currentLayerId,
        ...(groups
          .map((g) => g.layerId)
          .filter(isNotNull)
          .filter((id) => layersById[id] != null && !layersById[id].isDeleted) ?? []),
      ]),
    [groups, currentLayerId, layersById],
  );
  const isLoading = useAppSelector((state) =>
    series.some((s) =>
      entityLoadingAnyMonthInRangeSelector(state, {
        id: s.driverId,
        monthKeys: getMonthKeysForRange(start, end),
      }),
    ),
  );
  const timeSeries = useAppSelector((state) => {
    if (isLoading) {
      return null;
    }
    return Object.fromEntries(
      allLayerIds.map((layerId) => [
        layerId,
        Object.fromEntries(
          series.map((s) => [
            s.id,
            driverTimeSeriesForLayerSelector(state, {
              id: s.driverId,
              start: getMonthKey(start),
              end: getMonthKey(end),
              layerId,
            }),
          ]),
        ),
      ]),
    );
  }, deepEqual);

  useRequestCellValue({
    ids: series.map((s) => s.driverId),
    type: 'driver',
    dateRange: [start, end],
  });

  const data = useMemo(() => {
    if (timeSeries === null) {
      return null;
    }

    const seriesData: Array<Record<string, string | number | null>> = [];

    const monthKeys = getMonthKeysForRange(start, end);
    for (const monthKey of monthKeys) {
      const point: Record<string, any> = {
        monthKey,
      };

      for (const { id } of series) {
        const group = groups.find(
          (g) => g.seriesIds.includes(id),
          // @todo Brian Love - Ask Derrick if this is still necessary
          // || g.seriesIds.includes(driverId),
        );
        if (!group) {
          continue;
        }

        let layerId: LayerId;
        if (group.layerId != null) {
          layerId = group.layerId;
        } else {
          layerId = currentLayerId;
        }

        point[id] = timeSeries[layerId]?.[id]?.[monthKey];
      }

      seriesData.push(point);
    }

    return seriesData;
  }, [timeSeries, start, end, series, groups, currentLayerId]);

  return {
    isLoading,
    timeSeries,
    currentLayerId,
    data,
  };
}

/**
 * Custom hook that retrieves aggregated data for a chart driver.
 *
 * @param dateRange - A tuple representing the start and end date/time range.
 * @param series - An array of chart series.
 * @param groups - An array of chart groups.
 * @returns An object containing the aggregated data, along with other related properties.
 */
export function useChartAggregatedData(
  [start, end]: [DateTime, DateTime],
  series: ChartSeries[],
  groups: ChartGroup[],
) {
  const { isLoading, timeSeries, currentLayerId } = useChartTimeSeriesData(
    [start, end],
    series,
    groups,
  );

  const rollupReducers = useAppSelector((state) => {
    return Object.fromEntries(
      series.map((s) => {
        return [s.id, driverRollupReducerSelector(state, { id: s.driverId })];
      }),
    );
  }, deepEqual);

  const data = useMemo(() => {
    if (timeSeries === null) {
      return null;
    }

    const monthKeys = getMonthKeysForRange(start, end);

    return series.map((s) => {
      const value = applyRollupReducer({
        monthKeys,
        values: timeSeries[currentLayerId]?.[s.id],
        reducer: rollupReducers[s.id],
      }).value;

      return {
        value: value ?? 0,
        id: s.id,
      };
    });
  }, [currentLayerId, end, rollupReducers, series, start, timeSeries]);

  return {
    isLoading,
    currentLayerId,
    data,
  };
}
