globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"0e60b06c4d106cb0a55551b2ba80b86dad4057fc"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import { isDevelopment, isUserBrowser } from './src/helpers/environment';
import { getBaseSentryOptions } from './src/helpers/sentry';

Sentry.init({
  ...getBaseSentryOptions(),
  integrations: [
    Sentry.httpClientIntegration(),
    ...(isUserBrowser()
      ? [
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: true,
          }),
        ]
      : []),
  ],
  // see https://docs.sentry.io/platforms/javascript/configuration/integrations/httpclient/
  sendDefaultPii: true,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.2,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: isUserBrowser() && !isDevelopment ? 1.0 : 0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
});
