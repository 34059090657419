import {
  AgCartesianAxisOptions,
  AgCartesianChartOptions,
  AgChartLegendPosition,
  AgWaterfallSeriesOptions,
  AgWaterfallSeriesTooltipRendererParams,
  WaterfallSeriesTotalMeta,
} from 'ag-charts-community';
import { AgChartLegendLabelFormatterParams } from 'ag-charts-enterprise';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';

import AgChart from 'components/AgGridComponents/AgChart/AgChart';
import { renderTooltip } from 'components/AgGridComponents/AgChart/agChartTooltips';
import { useGroupColors } from 'components/CustomizeDriverChartsBlock/hooks';
import theme from 'config/theme';
import { ChartAxisType, ChartDisplay, ChartElementPosition, DriverFormat } from 'generated/graphql';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { Attribute } from 'reduxStore/models/dimensions';
import { DEFAULT_DISPLAY_CONFIGURATION, DisplayConfiguration } from 'reduxStore/models/value';
import { layersSelector } from 'selectors/layerSelector';

import {
  AgChartProps,
  CHART_TOOLTIP_CLASSNAME,
  CURRENCY_FORMAT,
  GREEN_1,
  NUMBER_FORMAT,
  PERCENT_FORMAT,
  RED_1,
} from './agCharts';
import { useChartAggregatedData, useChartConfig } from './chartHooks';

const { fonts } = theme;
const EMPTY_DATA: WaterfallData[] = [];

interface WaterfallData {
  id: string;
  label: string;
  value: number;
}

interface WaterfallDatum extends WaterfallData {
  axisLabel: string;
  index: number;
  totalType: 'subtotal' | 'total';
}

type WaterfallSeriesTotalMetaWithSeriesIds = WaterfallSeriesTotalMeta & { seriesIds: string[] };

function useChartDriverWaterfallTimeSeriesData(
  chartDisplay: ChartDisplay,
  [start, end]: [DateTime, DateTime],
  attributesBySeriesId: NullableRecord<string, Attribute[]>,
  seriesNameById: NullableRecord<string, string>,
) {
  const {
    isLoading,
    currentLayerId,
    data: ungroupedData,
  } = useChartAggregatedData([start, end], chartDisplay.series, chartDisplay.groups);

  const data = useMemo((): WaterfallData[] => {
    if (isLoading || !ungroupedData) {
      return EMPTY_DATA;
    }

    return chartDisplay.groups.flatMap((group) => {
      const seriesForGroup = chartDisplay.series.filter((series) => {
        return group.seriesIds.includes(series.id);
      });

      const groupedData = ungroupedData.filter(({ id }) => {
        return seriesForGroup.some((series) => series.id === id);
      });

      return groupedData.map(({ id, value }) => {
        const attributes = safeObjGet(attributesBySeriesId[id]) ?? [];
        const label = attributes.reduce(
          (prev, attr) => `${prev} (${attr.value})`,
          seriesNameById[id] ?? '',
        );
        return {
          id,
          label,
          value: Math.abs(value ?? 0) * (group.isPositive === true ? 1 : -1),
        };
      });
    });
  }, [
    attributesBySeriesId,
    chartDisplay.groups,
    chartDisplay.series,
    isLoading,
    ungroupedData,
    seriesNameById,
  ]);

  return {
    currentLayerId,
    data,
    isLoading,
  };
}

const AgWaterfallChart: React.FC<AgChartProps> = ({ chartDisplay }) => {
  const { blockId } = useBlockContext();
  const layersById = useAppSelector(layersSelector);
  const colorsByGroupId = useGroupColors(chartDisplay);
  const {
    attributesBySeriesId,
    dateRange,
    displayConfigurationBySeriesId,
    height,
    seriesNameById,
    totalDisplayConfiguration,
    width,
  } = useChartConfig(blockId, chartDisplay);
  const { isLoading, data } = useChartDriverWaterfallTimeSeriesData(
    chartDisplay,
    dateRange,
    attributesBySeriesId,
    seriesNameById,
  );

  /**
   * AG Chart totals are used to display the subtotal and total values for the waterfall chart.
   *
   * The index indicates the subtotal/total position within the data using a 0-based index.
   * As such, for groups that have the showSubtotal feature enabled, this index needs to be
   * cumulative for all groups and associated series. You can read more about this functionality
   * in the AG Charts documentation at:
   * https://www.ag-grid.com/charts/react/waterfall-series/#total--subtotal-values
   */
  const totals = useMemo<WaterfallSeriesTotalMetaWithSeriesIds[]>(() => {
    const totalMetas = chartDisplay.groups
      .filter((group) => group.showSubtotal)
      .map(
        (group, index, groups): WaterfallSeriesTotalMetaWithSeriesIds => ({
          totalType: 'subtotal',
          axisLabel: group.name ?? `Group ${index + 1} Subtotal`,
          index:
            groups.slice(0, index).reduce((prev, g) => prev + g.seriesIds.length, 0) +
            group.seriesIds.length -
            1,
          seriesIds: group.seriesIds,
        }),
      );
    const driverAxis = chartDisplay.axes.find((axis) => axis.type === ChartAxisType.Driver);
    if (driverAxis?.driver?.showTotals) {
      totalMetas.push({
        totalType: 'total',
        axisLabel: 'Total',
        index: data.length - 1,
        seriesIds: totalMetas.flatMap((t) => t.seriesIds),
      });
    }
    return totalMetas;
  }, [chartDisplay.axes, chartDisplay.groups, data.length]);

  const totalsByIndex = useMemo<
    Record<number, { value: number; displayConfiguration: DisplayConfiguration }>
  >(() => {
    const out: Record<number, { value: number; displayConfiguration: DisplayConfiguration }> = {};

    for (const total of totals) {
      const items = total.seriesIds.map((id) => data.find((d) => d.id === id)).filter(isNotNull);
      const sum = items.reduce((s, item) => s + item.value, 0);

      const seriesId = total.seriesIds.find((id) => displayConfigurationBySeriesId[id] != null);
      const displayConfiguration =
        seriesId != null && displayConfigurationBySeriesId[seriesId]
          ? displayConfigurationBySeriesId[seriesId]
          : DEFAULT_DISPLAY_CONFIGURATION;

      out[total.index] = {
        value: sum,
        displayConfiguration,
      };
    }

    return out;
  }, [data, displayConfigurationBySeriesId, totals]);

  const series: AgWaterfallSeriesOptions[] = useMemo(() => {
    const tooltipRenderer = (params: AgWaterfallSeriesTooltipRendererParams<WaterfallDatum>) => {
      const displayConfiguration =
        displayConfigurationBySeriesId[params.datum.id] ?? DEFAULT_DISPLAY_CONFIGURATION;

      // There is currently not an efficient method to render a tooltip for the subtotal or total in AG Charts.
      // Support ticket has been created and a new item in the backlog/roadmap has been created.
      // See: AG-12764 - [Charts] Add computed value to waterfall total/subtotal tooltip renderer params
      if (params.datum.totalType != null) {
        return renderTooltip(
          params.datum.label,
          totalsByIndex[params.datum.index].value,
          displayConfiguration,
        );
      }

      return renderTooltip(params.datum.label, params.datum.value, displayConfiguration);
    };

    const positiveGroup = chartDisplay.groups.find((group) => group.isPositive);
    const negativeGroup = chartDisplay.groups.find((group) => group.isPositive === false);

    return [
      {
        type: 'waterfall',
        xKey: 'label',
        yKey: 'value',
        totals,
        item: {
          positive: {
            fill: positiveGroup ? colorsByGroupId[positiveGroup.id].value : GREEN_1.value,
          },
          negative: {
            fill: negativeGroup ? colorsByGroupId[negativeGroup.id].value : RED_1.value,
          },
          total: {
            fill: '#d9d9d9',
          },
        },
        highlightStyle: {
          series: {
            enabled: true,
            dimOpacity: 0.5,
          },
        },
        tooltip: {
          enabled: true,
          showArrow: false,
          renderer: tooltipRenderer,
        },
      },
    ];
  }, [chartDisplay.groups, colorsByGroupId, displayConfigurationBySeriesId, totals, totalsByIndex]);

  const axes = useMemo(() => {
    const driverAxis = chartDisplay.axes.find((axis) => axis.type === ChartAxisType.Driver);
    const yAxis: AgCartesianAxisOptions = {
      gridLine: {
        enabled: false,
      },
      label: {
        color: 'gray.500',
        fontSize: 10,
        fontFamily: fonts.body,
        format:
          totalDisplayConfiguration.format === DriverFormat.Currency
            ? CURRENCY_FORMAT
            : totalDisplayConfiguration.format === DriverFormat.Percentage
              ? PERCENT_FORMAT
              : NUMBER_FORMAT,
      },
      line: { enabled: false },
      min: driverAxis?.driver?.min ?? undefined,
      max: driverAxis?.driver?.max ?? undefined,
      nice: driverAxis?.driver?.round !== false,
      position: driverAxis?.position === ChartElementPosition.Left ? 'left' : 'right',
      title: {
        enabled: Boolean(driverAxis?.showLabel),
        text: driverAxis?.name ?? '',
        fontFamily: fonts.body,
        fontSize: 10,
        color: 'gray.500',
        spacing: 8,
      },
      type: 'number',
    };

    const categoryAxis = chartDisplay.axes.find((axis) => axis.type === ChartAxisType.Category);
    const xAxis: AgCartesianAxisOptions = {
      gridLine: {
        enabled: false,
      },
      label: {
        color: 'gray.500',
        fontSize: 10,
        fontFamily: fonts.body,
      },
      line: { enabled: false },
      paddingInner: 0.2,
      paddingOuter: 0.1,
      position: categoryAxis?.position === ChartElementPosition.Top ? 'top' : 'bottom',
      title: {
        enabled: Boolean(categoryAxis?.showLabel),
        text: categoryAxis?.name ?? '',
        fontFamily: fonts.body,
        fontSize: 10,
        color: 'gray.500',
        spacing: 12,
      },
      type: 'category',
    };

    return [xAxis, yAxis];
  }, [chartDisplay.axes, totalDisplayConfiguration.format]);

  const options: AgCartesianChartOptions = useMemo(() => {
    return {
      axes,
      data,
      tooltip: {
        class: CHART_TOOLTIP_CLASSNAME,
        position: {
          type: 'pointer',
        },
      },
      legend: {
        enabled:
          chartDisplay.legend?.showLegend != null
            ? chartDisplay.legend.showLegend
            : chartDisplay.series.length > 1,
        reverseOrder: false,
        position:
          (chartDisplay.legend?.position?.toLowerCase() as AgChartLegendPosition) ?? 'bottom',
        preventHidingAll: true,
        maxWidth: chartDisplay.legend?.container?.maxWidth ?? undefined,
        maxHeight: chartDisplay.legend?.container?.maxHeight ?? undefined,
        item: {
          showSeriesStroke: false,
          marker: {
            size: 8,
            shape: 'square',
          },
          maxWidth: chartDisplay.legend?.item?.maxWidth ?? undefined,
          paddingX: chartDisplay.legend?.item?.paddingX ?? undefined,
          paddingY: chartDisplay.legend?.item?.paddingY ?? undefined,
          label: {
            fontFamily: fonts.body,
            fontSize: 10,
            color: 'gray.600',
            formatter: ({ itemId, value }: AgChartLegendLabelFormatterParams) => {
              let base = value;
              const id = String(itemId);

              const attributes = safeObjGet(attributesBySeriesId[id]);
              if (attributes != null && attributes.length > 0) {
                for (const attr of attributes) {
                  base += ` [${attr.value}]`;
                }
              }

              const group = chartDisplay?.groups.find((g) =>
                g.seriesIds.includes(itemId as string),
              );
              if (group?.layerId != null) {
                base += ` [${layersById[group.layerId].name}]`;
                return base;
              }

              return base;
            },
          },
        },
      },
      background: {
        visible: false,
      },
      series,
      width,
      height,
    };
  }, [
    attributesBySeriesId,
    axes,
    chartDisplay.groups,
    chartDisplay.legend,
    chartDisplay.series.length,
    data,
    height,
    layersById,
    series,
    width,
  ]);

  return <AgChart isLoading={isLoading} options={options} />;
};

export default React.memo(AgWaterfallChart);
