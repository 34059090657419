/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useToken } from '@chakra-ui/react';
import {
  AgAreaSeriesOptions,
  AgBarSeriesLabelFormatterParams,
  AgBarSeriesLabelOptions,
  AgBarSeriesOptions,
  AgCartesianChartOptions,
  AgCartesianCrossLineOptions,
  AgCartesianSeriesOptions,
  AgCartesianSeriesTooltipRendererParams,
  AgCategoryAxisOptions,
  AgChartLabelOptions,
  AgChartLegendOptions,
  AgChartLegendPosition,
  AgLineSeriesLabelFormatterParams,
  AgLineSeriesOptions,
  AgNumberAxisOptions,
  AgRangeAreaSeriesOptions,
  AgTooltipRendererResult,
  FontStyle,
  FontWeight,
} from 'ag-charts-community';
import { deepEqual } from 'fast-equals';
import { uniq } from 'lodash';
import React, { useMemo } from 'react';

import AgChart from 'components/AgGridComponents/AgChart/AgChart';
import {
  AgChartProps,
  CHART_TOOLTIP_CLASSNAME,
  CURRENCY_FORMAT,
  DEFAULT_FONT_SIZE,
  DEFAULT_FONT_STYLE,
  DEFAULT_FONT_WEIGHT,
  DEFAULT_TEXT_COLOR,
  NUMBER_FORMAT,
  PERCENT_FORMAT,
  SERIES_COLORS,
  getHeight,
  getWidth,
  toCartesianSeriesType,
} from 'components/AgGridComponents/AgChart/agCharts';
import theme from 'config/theme';
import colors from 'config/theme/foundations/colors';
import {
  ChartAxisType,
  ChartDisplay,
  ChartElementPosition,
  ChartGroup,
  ChartGroupingType,
  ChartSeries,
  ChartSeriesLabel,
  ChartSeriesType,
  ChartSize,
  DriverFormat,
  Maybe,
  ThresholdDirection,
} from 'generated/graphql';
import {
  getDateTimeFromMonthKey,
  getMonthKey,
  getMonthKeysForRange,
  isDateBetween,
  shortMonthFormat,
} from 'helpers/dates';
import { formatDriverValue } from 'helpers/formatting';
import { isNotNull, safeObjGet } from 'helpers/typescript';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import { useRequestCellValue } from 'hooks/useRequestCellValue';
import { LayerId } from 'reduxStore/models/layers';
import { DEFAULT_DISPLAY_CONFIGURATION, DisplayConfiguration } from 'reduxStore/models/value';
import { entityLoadingAnyMonthInRangeSelector } from 'selectors/calculationsSelector';
import {
  driverTimeSeriesForImpactShadingSelector,
  driverTimeSeriesForLayerSelector,
} from 'selectors/driverTimeSeriesSelector';
import {
  attributesBySubDriverIdSelector,
  driverNamesByIdSelector,
} from 'selectors/driversSelector';
import { driverDisplayConfigurationSelector } from 'selectors/entityDisplayConfigurationSelector';
import { lastActualsMonthKeyForLayerSelector } from 'selectors/lastActualsSelector';
import { currentLayerIdSelector, layersSelector } from 'selectors/layerSelector';
import { firstMilestoneForDriverSelector } from 'selectors/milestonesSelector';
import { blockDateRangeDateTimeSelector } from 'selectors/pageDateRangeSelector';
import { flattenedSelectedEventIdsSelector } from 'selectors/selectedEventSelector';
import { MonthKey } from 'types/datetime';

const { fonts } = theme;

const enableAxisLabels = (chartSize: ChartSize | undefined | null) => {
  return chartSize !== ChartSize.Medium;
};

const tooltipPosition = (chartSize: ChartSize | undefined | null) => {
  if (chartSize === ChartSize.Medium) {
    return 'pointer';
  }

  return undefined;
};

const makeSeriesIdWithImpact = (seriesId: string, ignoreEventIds: string[]) =>
  `${seriesId}:${ignoreEventIds.join(':')}`;

/**
 * Enable the mini legend when comparing scenarios.
 */
const enableMiniComparisonLegend = (
  driverIds: string[],
  groupingType: ChartGroupingType,
  chartDisplay: ChartDisplay,
) => {
  if (driverIds.length !== 1 || groupingType !== ChartGroupingType.Single) {
    return false;
  }

  const seriesIds = new Set(
    chartDisplay.series.filter((s) => s.driverId === driverIds[0]).map((s) => s.id),
  );

  return (
    chartDisplay.groups.filter(
      (g) => g.seriesIds.some((id) => seriesIds.has(id)) && g.layerId != null,
    ).length > 1
  );
};

const makeSeriesLabelOptions = <T extends 'bar' | 'line'>(
  type: T,
  showLabels: boolean,
  label: Maybe<ChartSeriesLabel> | undefined,
  driverDisplayConfigurationsById: Record<string, DisplayConfiguration>,
  driverId: string,
): T extends 'bar'
  ? AgBarSeriesLabelOptions<any, AgBarSeriesLabelFormatterParams>
  : AgChartLabelOptions<any, AgLineSeriesLabelFormatterParams> => {
  const common: AgChartLabelOptions<any, any> = {
    enabled: showLabels,
    fontFamily: fonts.body,
    fontSize: label?.fontSize ?? 10,
    fontStyle: (label?.fontStyle as FontStyle) ?? DEFAULT_FONT_STYLE,
    fontWeight: (label?.fontWeight as FontWeight) ?? DEFAULT_FONT_WEIGHT,
    color: label?.color ?? DEFAULT_TEXT_COLOR,
    formatter: ({ value }) => {
      const displayConfiguration =
        driverDisplayConfigurationsById[driverId] ?? DEFAULT_DISPLAY_CONFIGURATION;
      return formatDriverValue(Number(value), displayConfiguration, {
        abbreviate: true,
      });
    },
  };

  if (type === 'bar') {
    const barConfig: AgBarSeriesLabelOptions<any, AgBarSeriesLabelFormatterParams> = {
      ...common,
      placement: label?.position === ChartElementPosition.Top ? 'outside' : 'inside',
    };
    return barConfig;
  }

  return common;
};

const EMPTY_ARRAY: any[] = [];

const CHART_SERIES_TYPE_LAYER_PRIORITY: Record<ChartSeriesType, number> = {
  [ChartSeriesType.Area]: 0,
  [ChartSeriesType.Bar]: 1,
  [ChartSeriesType.Line]: 2,
  // Unsupported
  [ChartSeriesType.Donut]: -1,
  [ChartSeriesType.Nightingale]: -1,
  [ChartSeriesType.Pie]: -1,
  [ChartSeriesType.Treemap]: -1,
  [ChartSeriesType.Waterfall]: -1,
};

const AgComboChart: React.FC<AgChartProps> = ({
  driverIds,
  chartDisplay,
  size = ChartSize.Medium,
  groupingType = ChartGroupingType.Single,
  stacked = false,
  chartIndex,
}) => {
  const { blockId } = useBlockContext();
  const driverNamesById = useAppSelector(driverNamesByIdSelector);
  const attributesBySubDriverId = useAppSelector(attributesBySubDriverIdSelector);
  const [IMPACT_SHADE_AREA_FILL, AXIS_COLOR, LEGEND_TEXT_COLOR] = useToken('colors', [
    'blue.300',
    'gray.300',
    'gray.600',
  ]);

  const currentLayerId = useAppSelector(currentLayerIdSelector);
  const layersById = useAppSelector(layersSelector);

  const allLayerIds = useMemo(
    () =>
      uniq([
        currentLayerId,
        ...(chartDisplay?.groups
          .map((g) => g.layerId)
          .filter(isNotNull)
          .filter((id) => layersById[id] != null && !layersById[id].isDeleted) ?? []),
      ]),
    [chartDisplay?.groups, currentLayerId, layersById],
  );

  const filteredDriverIds = useMemo(() => {
    // When provided with chartIndex, only the ordinally assigned driver should be displayed.
    if (chartIndex != null) {
      return [driverIds[chartIndex]];
    }
    return driverIds;
  }, [driverIds, chartIndex]);

  const ignoreEventIds = useAppSelector(flattenedSelectedEventIdsSelector);
  const eventImpactDriverId = useMemo(() => {
    if (ignoreEventIds.length === 0 || filteredDriverIds.length !== 1) {
      return null;
    }
    return filteredDriverIds[0];
  }, [filteredDriverIds, ignoreEventIds.length]);
  const eventImpactSeriesId = useMemo(() => {
    if (eventImpactDriverId == null) {
      return null;
    }
    const seriesId = chartDisplay.series.find((s) => s.driverId === eventImpactDriverId)?.id;
    if (seriesId == null) {
      return null;
    }
    return makeSeriesIdWithImpact(seriesId, ignoreEventIds);
  }, [chartDisplay.series, eventImpactDriverId, ignoreEventIds]);

  const lastActualsMonthKey = useAppSelector(lastActualsMonthKeyForLayerSelector);
  const dateRange = useAppSelector((state) => blockDateRangeDateTimeSelector(state, blockId));
  const [start, end] = dateRange;
  const monthKeys = useMemo(() => getMonthKeysForRange(start, end), [start, end]);
  const isAnyDriverLoading = useAppSelector((state) =>
    allLayerIds.some((layerId) =>
      filteredDriverIds.some((driverId) =>
        entityLoadingAnyMonthInRangeSelector(state, {
          id: driverId,
          monthKeys,
          layerId,
        }),
      ),
    ),
  );

  const milestone = useAppSelector((state) =>
    groupingType === ChartGroupingType.Single
      ? firstMilestoneForDriverSelector(state, filteredDriverIds[0])
      : null,
  );

  const isLastActualsInRange = isDateBetween(
    lastActualsMonthKey,
    getMonthKey(start),
    getMonthKey(end),
  );

  useRequestCellValue({
    ids: filteredDriverIds,
    layerIds: allLayerIds,
    type: 'driver',
    dateRange,
  });
  // For impact shading.
  useRequestCellValue({
    ids: filteredDriverIds,
    layerIds: allLayerIds,
    type: 'driver',
    dateRange,
    ignoreEventIds,
  });

  const driverTimeSeries = useAppSelector((state) => {
    if (isAnyDriverLoading) {
      return null;
    }
    return Object.fromEntries(
      allLayerIds.map((layerId) => [
        layerId,
        Object.fromEntries(
          filteredDriverIds.map((id) => [
            id,
            driverTimeSeriesForLayerSelector(state, {
              id,
              start: getMonthKey(start),
              end: getMonthKey(end),
              layerId,
            }),
          ]),
        ),
      ]),
    );
  }, deepEqual);

  const eventImpactTimeSeries = useAppSelector((state) => {
    // Only one driver can ever have impact shading enabled.
    if (isAnyDriverLoading || eventImpactDriverId == null) {
      return null;
    }
    return driverTimeSeriesForImpactShadingSelector(state, {
      driverId: eventImpactDriverId,
      blockId,
      includeEventsInSameRow: false,
    });
  }, deepEqual);

  const driverDisplayConfigurationsById = useAppSelector((state) => {
    return Object.fromEntries(
      filteredDriverIds.map((driverId) => [
        driverId,
        driverDisplayConfigurationSelector(state, driverId),
      ]),
    );
  }, deepEqual);

  const milestoneCrossLine = useMemo(() => {
    if (milestone == null || driverDisplayConfigurationsById == null) {
      return null;
    }

    const isAbove = milestone.thresholdDirection === ThresholdDirection.AboveOrEqual;
    const defaultFormat = driverDisplayConfigurationsById[milestone.driverId];
    const text = formatDriverValue(milestone.value, defaultFormat, {
      abbreviate: true,
      abbreviatedDecimals: 1,
    });
    const crossLine: AgCartesianCrossLineOptions = {
      type: 'range',
      range: [milestone.value, isAbove ? Number.MAX_SAFE_INTEGER : 0],
      // TODO: hardcode milestone color until useToken is fixed for --chakra-colors-milestone
      fill: colors.purple[500],
      stroke: colors.purple[500],
      fillOpacity: 0.07,
      lineDash: [2],
      label: {
        enabled: true,
        text,
        position: isAbove ? 'insideBottomLeft' : 'insideTopLeft',
        color: colors.purple[500],
        fontWeight: DEFAULT_FONT_WEIGHT,
        fontSize: DEFAULT_FONT_SIZE,
        fontFamily: fonts.body,
      },
    };
    return crossLine;
  }, [driverDisplayConfigurationsById, milestone]);

  const monthAxisCrossLines = useMemo(() => {
    if (
      isAnyDriverLoading ||
      driverTimeSeries == null ||
      driverDisplayConfigurationsById == null ||
      filteredDriverIds.length < 2 ||
      chartDisplay == null
    ) {
      return EMPTY_ARRAY;
    }

    const axisWithTotals = chartDisplay.axes.find((axis) => axis.driver?.showTotals);
    if (!axisWithTotals) {
      return EMPTY_ARRAY;
    }

    const groupIds = axisWithTotals.driver?.groupIds;
    if (!groupIds || groupIds.length === 0) {
      return EMPTY_ARRAY;
    }

    // Only keep drivers relevant to this chart.
    const seriesIds = new Set(
      chartDisplay.series
        .filter((s) =>
          s.driverId == null
            ? // Backwards compatible use.
              filteredDriverIds.includes(s.id)
            : // Preferred use.
              filteredDriverIds.includes(s.driverId),
        )
        .filter((s) => s.type === ChartSeriesType.Bar)
        .map((s) => s.id),
    );

    const groupSeriesPairs = groupIds
      .map((gid) => {
        const group = chartDisplay.groups.find((g) => g.id === gid);
        if (!group || !group.seriesIds.some((sid) => seriesIds.has(sid))) {
          return null;
        }
        return group;
      })
      .filter(isNotNull)
      .map(
        (g) =>
          [
            g,
            g.seriesIds
              .filter((sid) => seriesIds.has(sid))
              .map((sid) => chartDisplay.series.find((s) => s.id === sid))
              .filter(isNotNull),
          ] as [ChartGroup, ChartSeries[]],
      );
    const firstDriverId = safeObjGet(groupSeriesPairs[0]?.[1]?.[0]?.driverId);

    const sums: Record<MonthKey, number> = {};
    for (const monthKey of monthKeys) {
      sums[monthKey] = 0;

      for (const [group, series] of groupSeriesPairs) {
        for (const { id, driverId } of series) {
          sums[monthKey] +=
            driverTimeSeries[group.layerId ?? currentLayerId]?.[driverId ?? id]?.[monthKey] ?? 0;
        }
      }
    }

    const crossLineOptions: AgCartesianCrossLineOptions[] = [];
    for (const [monthKey, sum] of Object.entries(sums)) {
      const defaultFormat = driverDisplayConfigurationsById[firstDriverId ?? filteredDriverIds[0]];
      const text = formatDriverValue(sum, defaultFormat, {
        abbreviate: true,
        abbreviatedDecimals: 1,
      });
      crossLineOptions.push({
        type: 'line',
        value: monthKey,
        fill: 'transparent',
        strokeWidth: 0,
        label: {
          text,
          padding: 8,
          fontFamily: fonts.body,
          fontWeight: 500,
          fontSize: DEFAULT_FONT_SIZE,
          color: DEFAULT_TEXT_COLOR,
          position: 'top',
          rotation: -50,
        },
      });
    }

    return crossLineOptions;
  }, [
    isAnyDriverLoading,
    driverTimeSeries,
    driverDisplayConfigurationsById,
    filteredDriverIds,
    chartDisplay,
    monthKeys,
    currentLayerId,
  ]);

  const axes = useMemo(() => {
    const axesOptions: AgCartesianChartOptions['axes'] = [];
    if (
      filteredDriverIds.length === 0 ||
      driverDisplayConfigurationsById == null ||
      chartDisplay == null
    ) {
      return EMPTY_ARRAY;
    }

    for (const { type, driver, position, name, showLabel } of chartDisplay.axes) {
      if (type === ChartAxisType.Time) {
        continue;
      }

      const groupIds = driver?.groupIds;
      if (!groupIds || groupIds.length === 0) {
        continue;
      }

      const groupSeriesIds = chartDisplay.groups.reduce<string[]>(
        (arr, g) => (groupIds.includes(g.id) ? [...arr, ...g.seriesIds] : arr),
        [],
      );

      // Only keep drivers relevant to this chart.
      const series = chartDisplay.series.filter((s) => groupSeriesIds.includes(s.id));
      const seriesIds = series
        .filter((s) =>
          s.driverId == null
            ? // Backwards incompatible use.
              filteredDriverIds.includes(s.id)
            : // Preferred use.
              filteredDriverIds.includes(s.driverId),
        )
        .map((s) => s.id);

      if (eventImpactSeriesId != null) {
        seriesIds.push(eventImpactSeriesId);
      }

      // Pick the first driver display configuration in the axis group to use for axis ticks.
      const displayConfiguration =
        series[0] == null ? null : safeObjGet(driverDisplayConfigurationsById[series[0].driverId]);

      const yAxis: AgNumberAxisOptions = {
        type: 'number',
        position: position === ChartElementPosition.Left ? 'left' : 'right',
        keys: seriesIds,
        line: { enabled: false },
        nice: driver.round !== false,
        min: driver.min ?? undefined,
        max: driver.max ?? undefined,
        crosshair: {
          enabled: true,
          label: {
            enabled: true,
            className: CHART_TOOLTIP_CLASSNAME,
          },
        },
        gridLine: {
          enabled: false,
        },
        title: {
          enabled: Boolean(showLabel),
          text: name ?? '',
          fontFamily: fonts.body,
          fontSize: DEFAULT_FONT_SIZE,
          color: DEFAULT_TEXT_COLOR,
          spacing: 8,
        },
        label: {
          color: DEFAULT_TEXT_COLOR,
          fontSize: 10,
          fontFamily: fonts.body,
          format: driver.isNormalized
            ? NUMBER_FORMAT
            : displayConfiguration?.format === DriverFormat.Currency
              ? CURRENCY_FORMAT
              : displayConfiguration?.format === DriverFormat.Percentage
                ? PERCENT_FORMAT
                : NUMBER_FORMAT,
        },
      };
      axesOptions.push(yAxis);
    }

    if (milestoneCrossLine != null && axesOptions.length > 0) {
      axesOptions[0].crossLines = [milestoneCrossLine];
    }

    const timeAxis = chartDisplay?.axes.find(({ type }) => type === ChartAxisType.Time);
    const xAxis: AgCategoryAxisOptions = {
      type: 'category',
      position: timeAxis?.position === ChartElementPosition.Top ? 'top' : 'bottom',
      paddingInner: 0.2,
      paddingOuter: 0.1,
      crosshair: {
        enabled: true,
        label: {
          enabled: true,
          className: CHART_TOOLTIP_CLASSNAME,
        },
      },
      gridLine: {
        enabled: false,
      },
      line: { enabled: false },
      title: {
        enabled: Boolean(timeAxis?.showLabel),
        text: timeAxis?.name ?? '',
        fontFamily: fonts.body,
        fontSize: DEFAULT_FONT_SIZE,
        color: DEFAULT_TEXT_COLOR,
        spacing: 12,
      },
      label: {
        autoRotate: false,
        color: DEFAULT_TEXT_COLOR,
        fontSize: DEFAULT_FONT_SIZE,
        fontFamily: fonts.body,
        formatter: (params) => {
          return shortMonthFormat(getDateTimeFromMonthKey(String(params.value)));
        },
      },
      interval: {
        values: enableAxisLabels(size)
          ? undefined
          : [monthKeys[0], monthKeys[monthKeys.length - 1]],
      },
      crossLines: monthAxisCrossLines,
      keys: ['monthKey'],
    };
    if (isLastActualsInRange) {
      xAxis.crossLines = [
        ...(xAxis.crossLines ?? []),
        {
          type: 'line',
          enabled: true,
          value: lastActualsMonthKey,
          fillOpacity: 0,
          strokeWidth: 1,
          strokeOpacity: 0,
          stroke: AXIS_COLOR,
          label: {
            color: DEFAULT_TEXT_COLOR,
            fontSize: 8,
            padding: monthAxisCrossLines.length > 0 ? 55 : 12,
            fontFamily: fonts.body,
            fontWeight: 600,
            position: 'top',
            text: 'LAST CLOSE',
          },
        },
      ];
    }
    axesOptions.push(xAxis);

    return axesOptions;
  }, [
    filteredDriverIds,
    driverDisplayConfigurationsById,
    chartDisplay,
    milestoneCrossLine,
    size,
    monthKeys,
    monthAxisCrossLines,
    isLastActualsInRange,
    eventImpactSeriesId,
    lastActualsMonthKey,
    AXIS_COLOR,
  ]);

  const series = useMemo<AgCartesianSeriesOptions[]>(() => {
    if (
      chartDisplay == null ||
      driverTimeSeries == null ||
      driverDisplayConfigurationsById == null
    ) {
      return EMPTY_ARRAY;
    }

    return (
      chartDisplay.series
        // Only keep drivers relevant to this chart.
        .filter((s) =>
          s.driverId == null
            ? // Backwards compatible use.
              filteredDriverIds.includes(s.id)
            : // Preferred use.
              filteredDriverIds.includes(s.driverId),
        )
        .sort(
          (a, b) =>
            CHART_SERIES_TYPE_LAYER_PRIORITY[a.type] - CHART_SERIES_TYPE_LAYER_PRIORITY[b.type],
        )
        .map(
          (
            { id: seriesId, driverId, type, color, showLabels, label },
            index,
          ): AgCartesianSeriesOptions => {
            const group = chartDisplay.groups.find((g) => g.seriesIds.includes(seriesId));

            const config: AgCartesianSeriesOptions = {
              type: toCartesianSeriesType(type),
              xKey: 'monthKey',
              yKey: seriesId,
              yName: driverNamesById[driverId],
              highlightStyle: {
                series: {
                  // Only highlight the series on hover when impact shading is disabled.
                  enabled: ignoreEventIds.length === 0,
                  dimOpacity: 0.3,
                },
              },
              tooltip: {
                enabled: true,
                range: 'nearest',
                showArrow: false,
                position: {
                  type: tooltipPosition(size),
                  xOffset: tooltipPosition(size) === 'pointer' ? 20 : 0,
                  yOffset: tooltipPosition(size) === 'pointer' ? -30 : 0,
                },
                interaction: {
                  enabled: true,
                },
                renderer: (
                  params: AgCartesianSeriesTooltipRendererParams<Record<string, number>>,
                ): string | AgTooltipRendererResult => {
                  const { xKey, yKey, datum } = params;
                  let { title } = params;

                  const value = Number(datum[yKey]);
                  let content = formatDriverValue(
                    value,
                    driverDisplayConfigurationsById[driverId],
                    {
                      abbreviate: true,
                    },
                  );
                  const monthKey = String(datum[xKey]);

                  const attributes = safeObjGet(attributesBySubDriverId[driverId ?? seriesId]);
                  if (attributes != null && attributes.length > 0) {
                    for (const attr of attributes) {
                      title += ` [${attr.value}]`;
                    }
                  }

                  if (group?.layerId != null) {
                    title += ` [${layersById[group.layerId].name}]`;
                  }

                  content += ` on ${shortMonthFormat(getDateTimeFromMonthKey(monthKey))}`;

                  return {
                    title,
                    content,
                  };
                },
              },
            };

            if (type === ChartSeriesType.Bar) {
              const barConfig = config as AgBarSeriesOptions;

              const groupId = group?.id;
              if (groupId != null) {
                const axis = chartDisplay.axes.find((a) => a.driver?.groupIds.includes(groupId));
                barConfig.stackGroup = groupId;
                barConfig.normalizedTo = axis?.driver?.isNormalized ? 100 : undefined;
              } else {
                // If a bar is missing from a group, hide the series.
                // Do not remove the series entirely because it allows the user to debug.
                barConfig.visible = false;
                barConfig.showInLegend = false;
              }

              barConfig.grouped = !stacked;
              barConfig.stacked = stacked;

              const barColor =
                color ?? SERIES_COLORS[(chartIndex ?? index) % SERIES_COLORS.length].value;
              barConfig.fill = barColor;
              barConfig.stroke = barColor;

              if (stacked) {
                barConfig.stroke = 'white';
                barConfig.strokeWidth = 1.5;
                barConfig.strokeOpacity = 1;
                barConfig.fillOpacity = 0.95;
              }

              if (showLabels) {
                barConfig.label = makeSeriesLabelOptions(
                  'bar',
                  showLabels,
                  label,
                  driverDisplayConfigurationsById,
                  driverId,
                );
              }

              return barConfig;
            }

            if (type === ChartSeriesType.Line) {
              const lineConfig = config as AgLineSeriesOptions;

              const lineColor =
                color ?? SERIES_COLORS[(chartIndex ?? index) % SERIES_COLORS.length].value;
              lineConfig.stroke = lineColor;
              lineConfig.strokeWidth = 2;
              lineConfig.marker = {
                // A bug exists where disabling the marker makes the tooltip render in the wrong color.
                // Instead shrink the marker size to 1 pixel instead of disabling the market altogether.
                size: 1,
                fill: lineColor,
                fillOpacity: 1,
                strokeOpacity: 0,
                itemStyler: () => {
                  // You have to use "itemStyler" here because if you set fill opacity to 0 on the marker element the legend's color disappears :(
                  return { size: 30, fillOpacity: 0 };
                },
              };

              if (showLabels) {
                lineConfig.label = makeSeriesLabelOptions(
                  'line',
                  showLabels,
                  label,
                  driverDisplayConfigurationsById,
                  driverId,
                );
              }

              return lineConfig;
            }

            if (type === ChartSeriesType.Area) {
              const areaConfig = config as AgAreaSeriesOptions;

              const groupId = group?.id;
              if (groupId != null) {
                const axis = chartDisplay.axes.find((a) => a.driver?.groupIds.includes(groupId));
                areaConfig.normalizedTo = axis?.driver?.isNormalized ? 100 : undefined;
              }

              areaConfig.stacked = stacked;

              const areaColor =
                color ?? SERIES_COLORS[(chartIndex ?? index) % SERIES_COLORS.length].value;
              areaConfig.fill = areaColor;
              areaConfig.stroke = areaColor;
              areaConfig.marker = { size: 0.5 };
              areaConfig.strokeWidth = 1;
              areaConfig.fillOpacity = 1;

              if (stacked) {
                areaConfig.stroke = 'white';
                areaConfig.strokeWidth = 2;
                areaConfig.strokeOpacity = 1;
                areaConfig.fillOpacity = 0.9;
              }

              return areaConfig;
            }

            return config;
          },
        )
    );
  }, [
    chartDisplay,
    driverTimeSeries,
    driverDisplayConfigurationsById,
    filteredDriverIds,
    driverNamesById,
    ignoreEventIds.length,
    size,
    attributesBySubDriverId,
    layersById,
    stacked,
    chartIndex,
  ]);

  const eventImpactSeries = useMemo<AgRangeAreaSeriesOptions | null>(() => {
    if (eventImpactSeriesId == null) {
      return null;
    }

    const driverId = filteredDriverIds[0];
    const seriesItem = chartDisplay.series.find((s) => s.driverId === driverId);
    if (driverId == null || seriesItem == null) {
      return null;
    }

    const rangeArea: AgRangeAreaSeriesOptions = {
      type: 'range-area',
      xKey: 'monthKey',
      yHighKey: seriesItem.id,
      yLowKey: eventImpactSeriesId,
      fill: IMPACT_SHADE_AREA_FILL,
      strokeWidth: 0,
    };

    return rangeArea;
  }, [chartDisplay.series, eventImpactSeriesId, filteredDriverIds, IMPACT_SHADE_AREA_FILL]);

  const data = useMemo(() => {
    if (!driverTimeSeries) {
      return null;
    }

    const seriesData: Array<Record<string, any>> = [];
    for (const monthKey of monthKeys) {
      const point: Record<string, any> = {
        monthKey,
      };

      const dataSeries = chartDisplay.series
        // Only keep drivers relevant to this chart.
        .filter((s) =>
          s.driverId == null
            ? // Backwards compatible use.
              filteredDriverIds.includes(s.id)
            : // Preferred use.
              filteredDriverIds.includes(s.driverId),
        );
      for (const { id, driverId } of dataSeries) {
        const group = chartDisplay.groups.find(
          (g) =>
            g.seriesIds.includes(id) ||
            // backwards compatible
            g.seriesIds.includes(driverId),
        );
        if (!group) {
          continue;
        }

        let layerId: LayerId;
        if (group.layerId != null) {
          layerId = group.layerId;
        } else {
          layerId = currentLayerId;
        }

        point[id] = driverTimeSeries[layerId]?.[driverId]?.[monthKey];

        if (eventImpactSeriesId != null && eventImpactTimeSeries != null) {
          point[eventImpactSeriesId] = eventImpactTimeSeries[monthKey];
        }
      }

      seriesData.push(point);
    }

    return seriesData;
  }, [
    driverTimeSeries,
    monthKeys,
    chartDisplay.series,
    chartDisplay.groups,
    filteredDriverIds,
    eventImpactSeriesId,
    eventImpactTimeSeries,
    currentLayerId,
  ]);

  const options: AgCartesianChartOptions = useMemo(() => {
    const width = getWidth(size);
    const height = getHeight(size);

    if (
      axes.length === 0 ||
      series.length === 0 ||
      data == null ||
      driverDisplayConfigurationsById == null
    ) {
      return {
        width,
        height,
      };
    }

    let legend: AgChartLegendOptions;
    if (enableMiniComparisonLegend(filteredDriverIds, groupingType, chartDisplay)) {
      legend = {
        enabled: chartDisplay.legend != null && chartDisplay.legend?.showLegend !== false,
        position: 'bottom',
        preventHidingAll: true,
        spacing: 10,
        item: {
          marker: {
            size: 8,
            shape: 'square',
          },
          showSeriesStroke: false,
          label: {
            fontFamily: fonts.body,
            fontSize: DEFAULT_FONT_SIZE,
            color: LEGEND_TEXT_COLOR,
            formatter: ({ itemId, value }) => {
              const group = chartDisplay?.groups.find((g) =>
                g.seriesIds.includes(itemId as string),
              );
              if (!group || group.layerId == null) {
                return value;
              }
              return layersById[group.layerId]?.name ?? value;
            },
          },
        },
      };
    } else {
      legend = {
        enabled:
          chartDisplay.legend?.showLegend != null
            ? chartDisplay.legend.showLegend
            : filteredDriverIds.length > 1,
        reverseOrder: false,
        position:
          (chartDisplay.legend?.position?.toLowerCase() as AgChartLegendPosition) ?? 'right',
        preventHidingAll: true,
        maxWidth: chartDisplay.legend?.container?.maxWidth ?? undefined,
        maxHeight: chartDisplay.legend?.container?.maxHeight ?? undefined,
        item: {
          showSeriesStroke: false,
          marker: {
            size: 8,
            shape: 'square',
          },
          maxWidth: chartDisplay.legend?.item?.maxWidth ?? undefined,
          paddingX: chartDisplay.legend?.item?.paddingX ?? undefined,
          paddingY: chartDisplay.legend?.item?.paddingY ?? undefined,
          label: {
            fontFamily: fonts.body,
            fontSize: DEFAULT_FONT_SIZE,
            color: LEGEND_TEXT_COLOR,
            formatter: ({ itemId, value }) => {
              let base = value;

              const found = chartDisplay?.series.find((s) => s.id === itemId);
              if (!found) {
                return base;
              }

              const attributes = safeObjGet(attributesBySubDriverId[found.driverId ?? found.id]);
              if (attributes != null && attributes.length > 0) {
                for (const attr of attributes) {
                  base += ` [${attr.value}]`;
                }
              }

              const group = chartDisplay?.groups.find((g) =>
                g.seriesIds.includes(itemId as string),
              );
              if (group?.layerId != null) {
                base += ` [${layersById[group.layerId].name}]`;
                return base;
              }

              return base;
            },
          },
        },
      };
    }

    return {
      background: { visible: false },
      axes,
      data: isAnyDriverLoading ? [] : data,
      series: eventImpactSeries != null ? [...series, eventImpactSeries] : series,
      sync: {
        enabled: true,
        // Only sync charts within the same block; AG Charts appears to make odd manipulations of axes across different blocks.
        groupId: blockId,
      },
      tooltip: {
        class: CHART_TOOLTIP_CLASSNAME,
        position: {
          type: 'pointer',
        },
      },
      legend,
      width,
      height,
    };
  }, [
    attributesBySubDriverId,
    axes,
    blockId,
    chartDisplay,
    data,
    driverDisplayConfigurationsById,
    eventImpactSeries,
    filteredDriverIds,
    groupingType,
    isAnyDriverLoading,
    layersById,
    series,
    size,
    LEGEND_TEXT_COLOR,
  ]);

  return <AgChart isLoading={isAnyDriverLoading || data == null} options={options} />;
};

export default React.memo(AgComboChart);
