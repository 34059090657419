import { Box, Flex } from '@chakra-ui/react';
// eslint-disable-next-line you-dont-need-lodash-underscore/clone-deep
import { cloneDeep } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';

import { AgChartProps } from 'components/AgGridComponents/AgChart/agCharts';
import AgComboChart from 'components/AgGridComponents/AgChart/AgComboChart';
import AgDonutChart from 'components/AgGridComponents/AgChart/AgDonutChart';
import AgNightingaleChart from 'components/AgGridComponents/AgChart/AgNightingaleChart';
import AgPolarChart from 'components/AgGridComponents/AgChart/AgPieChart';
import AgTreemapChart from 'components/AgGridComponents/AgChart/AgTreemapChart';
import AgWaterfallChart from 'components/AgGridComponents/AgChart/AgWaterfallChart';
import BarChart from 'components/DriverChart/BarChart';
import DriverChart from 'components/DriverChart/DriverChart';
import DriverChartCardHeader from 'components/DriverChartCardHeader/DriverChartCardHeader';
import DriverChartContextProvider from 'components/DriverChartContextProvider/DriverChartContextProvider';
import DriverValueChart from 'components/DriverValueChart';
import DropTargets from 'components/Reorderable/DropTargets';
import { DEFAULT_DRIVER_VIEW_AS_TYPE } from 'config/block';
import { BlockViewAsType, ChartSeriesType } from 'generated/graphql';
import { toPxString } from 'helpers/styles';
import { uuidv4 } from 'helpers/uuidv4';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBlockContext from 'hooks/useBlockContext';
import useReorderable, { BaseDragItem } from 'hooks/useReorderable';
import { updateDriverPositionInBlock } from 'reduxStore/actions/driverReferenceMutations';
import { DriverId } from 'reduxStore/models/drivers';
import { isMultiDriverChart } from 'reduxStore/reducers/helpers/viewOptions';
import { blockConfigViewOptionsSelector } from 'selectors/blocksSelector';
import { driverChartCardDimensionsSelector } from 'selectors/driverChartCardDimensionsSelector';
import { enableAgChartsSelector } from 'selectors/launchDarklySelector';

const DRIVER_CHART_CARD_DRAG_ITEM_TYPE = 'driverChartCardDragItem';

interface Props {
  driverIds: DriverId[];
  showDriverDetailsButton?: boolean;
  isPlaceholder?: boolean;
  chartIndex?: number;
  isLast?: boolean;
  isEditable?: boolean;
  showEventImpact?: boolean;
  disableAgCharts?: boolean;
}

type ChartCardDragItem = BaseDragItem & { driverId: DriverId };

export const useDriverChartCardsDnD = (driverId: DriverId) => {
  const dispatch = useAppDispatch();
  const { blockId } = useBlockContext();
  const onDrop = useCallback(
    (dropItem: ChartCardDragItem, relativeTo: ChartCardDragItem, position: 'before' | 'after') => {
      dispatch(
        updateDriverPositionInBlock({
          blockId,
          driverIds: [dropItem.driverId],
          relativeToDriverId: relativeTo.driverId,
          position,
        }),
      );
    },
    [blockId, dispatch],
  );

  const dragItem = useMemo(
    () => ({ type: DRIVER_CHART_CARD_DRAG_ITEM_TYPE, driverId }),
    [driverId],
  );

  return { onDrop, dragItem, itemType: DRIVER_CHART_CARD_DRAG_ITEM_TYPE };
};

/**
 * TODO: refactor this component to exclude chart display logic from drag-and-drop.
 */
const DriverChartCard: React.FC<Props> = ({
  driverIds,
  showDriverDetailsButton = true,
  chartIndex,
  isLast = false,
  isEditable = true,
  showEventImpact,
  disableAgCharts = false,
}) => {
  const { blockId } = useBlockContext();
  const viewOptions = useAppSelector((state) => blockConfigViewOptionsSelector(state, blockId));
  const enableAgCharts = useAppSelector(enableAgChartsSelector);

  const multiDriver = isMultiDriverChart(viewOptions);
  const disabled = !multiDriver && !isEditable;

  // If its not a multidriver, we just use the first driverId
  const singleDriverId = chartIndex != null ? driverIds[chartIndex] : driverIds[0];
  // We only support drag/drop on single driver charts, so we dont mind using firstDriverId here
  const { onDrop, dragItem, itemType } = useDriverChartCardsDnD(singleDriverId);

  const chartContext = useMemo(
    () => (chartIndex != null ? { driverIds: [driverIds[chartIndex]] } : { driverIds }),
    [chartIndex, driverIds],
  );

  const { ref, inView } = useInView();

  // Use directly because we want to restrict dragging to the drag handle while
  // also using the entire chart card as the drop target.
  const { dragRef, previewRef, startDropRef, endDropRef, isOverStart, isOverEnd, isDragging } =
    useReorderable({
      itemType,
      item: dragItem,
      onDrop,
      disabled,
    });

  const viewAs = viewOptions?.viewAsType ?? DEFAULT_DRIVER_VIEW_AS_TYPE;

  const { width, height } = useAppSelector((state) =>
    driverChartCardDimensionsSelector(state, blockId),
  );

  const { chartDisplay } = viewOptions;
  const chartProps = useMemo(() => {
    if (!chartDisplay) {
      return null;
    }

    const props: AgChartProps = {
      chartDisplay,
      driverIds,
    };
    if (viewOptions.chartSize != null) {
      props.size = viewOptions.chartSize;
    }
    if (viewOptions.chartGroupingType != null) {
      props.groupingType = viewOptions.chartGroupingType;
    }
    if (viewOptions.aggregateValues != null) {
      props.stacked = viewOptions.aggregateValues;
    }

    // The plan detail pane determines which drivers to show KPI impacts charts
    // based on timeseries calculations, not on which drivers have been added to
    // the block. Manually insert those drivers here instead.
    if (driverIds.length > 0 && chartDisplay.series.length === 0) {
      props.chartDisplay = cloneDeep(chartDisplay);
      const seriesIds = driverIds.map(() => uuidv4());
      props.chartDisplay.series = driverIds.map((driverId, index) => ({
        id: seriesIds[index],
        driverId,
        type: ChartSeriesType.Line,
      }));
      props.chartDisplay.groups = props.chartDisplay.groups.map((g) =>
        g.isDefault ? { ...g, seriesIds } : g,
      );
    }

    return props;
  }, [
    chartDisplay,
    driverIds,
    viewOptions.aggregateValues,
    viewOptions.chartGroupingType,
    viewOptions.chartSize,
  ]);

  if (!chartProps && enableAgCharts) {
    return null;
  }

  const enableAgComboChart = !disableAgCharts && enableAgCharts && chartProps != null;

  return (
    <Box ref={previewRef} role={multiDriver ? '' : 'group'}>
      <DriverChartContextProvider {...chartContext}>
        <Box
          ref={ref}
          w={toPxString(width)}
          h={toPxString(height)}
          // Don't set x padding so we can let chart interactivity extend the whole
          // width of the card
          position="relative"
          userSelect="none"
          overflow="hidden"
        >
          <Flex flexDirection="column" h="full" w="full" py={3}>
            {!multiDriver && (
              <DriverChartCardHeader
                showDriverDetailsButton={showDriverDetailsButton}
                showCursorValue={inView && viewAs !== BlockViewAsType.CurrentValue}
                dragHandleRef={dragRef}
                disableRemoving={disabled}
                driverId={singleDriverId}
                disableAgCharts={disableAgCharts}
              />
            )}
            {(viewAs === BlockViewAsType.Chart ||
              viewAs === BlockViewAsType.LineChart ||
              viewAs === BlockViewAsType.AreaChart) &&
              (enableAgComboChart ? (
                <AgComboChart {...chartProps} chartIndex={chartIndex} />
              ) : (
                <DriverChart showEventImpact={showEventImpact} inView={inView} />
              ))}
            {viewAs === BlockViewAsType.CurrentValue && (
              <DriverValueChart driverId={singleDriverId} />
            )}
            {viewAs === BlockViewAsType.BarChart &&
              (enableAgComboChart ? (
                <AgComboChart {...chartProps} chartIndex={chartIndex} />
              ) : (
                <BarChart
                  showEventImpact={showEventImpact}
                  inView={inView}
                  multiDriver={multiDriver}
                />
              ))}
            {viewAs === BlockViewAsType.ComboChart && chartProps != null && (
              <AgComboChart {...chartProps} />
            )}
            {viewAs === BlockViewAsType.PieChart && chartProps != null && (
              <AgPolarChart {...chartProps} chartIndex={chartIndex} />
            )}
            {viewAs === BlockViewAsType.DonutChart && chartProps != null && (
              <AgDonutChart {...chartProps} chartIndex={chartIndex} />
            )}
            {viewAs === BlockViewAsType.NightingaleChart && chartProps != null && (
              <AgNightingaleChart {...chartProps} chartIndex={chartIndex} />
            )}
            {viewAs === BlockViewAsType.TreemapChart && chartProps != null && (
              <AgTreemapChart {...chartProps} chartIndex={chartIndex} />
            )}
            {viewAs === BlockViewAsType.WaterfallChart && chartProps != null && (
              <AgWaterfallChart {...chartProps} chartIndex={chartIndex} />
            )}
          </Flex>
          {isDragging && (
            <DropTargets
              startDropRef={startDropRef}
              endDropRef={endDropRef}
              orientation="horizontal"
              isLast={isLast}
              isOverStart={isOverStart}
              isOverEnd={isOverEnd}
            />
          )}
        </Box>
      </DriverChartContextProvider>
    </Box>
  );
};

export default React.memo(DriverChartCard);
