import {
  AgPieSeriesTooltipRendererParams,
  AgPolarChartOptions,
  AgPolarSeriesOptions,
} from 'ag-charts-community';
import React, { useMemo } from 'react';

import AgChart from 'components/AgGridComponents/AgChart/AgChart';
import { safeObjGet } from 'helpers/typescript';
import useBlockContext from 'hooks/useBlockContext';
import { DEFAULT_DISPLAY_CONFIGURATION } from 'reduxStore/models/value';

import { AgChartProps, CHART_TOOLTIP_CLASSNAME } from './agCharts';
import { renderTooltip } from './agChartTooltips';
import { useChartConfig, useChartTimeSeriesData } from './chartHooks';

const AgNightingaleChart: React.FC<AgChartProps> = ({ chartDisplay, chartIndex, driverIds }) => {
  const { blockId } = useBlockContext();
  const {
    attributesBySeriesId,
    chartConfig,
    colorBySeriesId,
    dateRange,
    displayConfigurationBySeriesId,
    height,
    seriesNameById,
    width,
  } = useChartConfig(blockId, chartDisplay);

  const filteredSeries = useMemo(() => {
    if (chartIndex != null) {
      const series = chartDisplay.series.find((s) => s.driverId === driverIds[chartIndex]);
      if (series) {
        return [series];
      }
    }
    return chartDisplay.series;
  }, [chartIndex, chartDisplay.series, driverIds]);

  const { isLoading, data } = useChartTimeSeriesData(
    dateRange,
    filteredSeries,
    chartDisplay.groups,
  );

  const series = useMemo((): AgPolarSeriesOptions[] => {
    if (!chartConfig.chartDisplay) {
      return [];
    }

    return filteredSeries.map((s) => ({
      type: 'nightingale',
      angleKey: 'monthKey',
      radiusKey: s.id,
      radiusName: safeObjGet(seriesNameById[s.id]),
      grouped: true,
      fill: colorBySeriesId[s.id],
      highlightStyle: {
        series: {
          enabled: true,
          dimOpacity: 0.5,
        },
      },
      label: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
        showArrow: false,
        renderer: ({
          radiusKey,
          title,
          datum,
        }: AgPieSeriesTooltipRendererParams<Record<string, number>>) => {
          const id = String(radiusKey);
          const value = Number(datum[id]);
          const displayConfiguration =
            displayConfigurationBySeriesId[id] ?? DEFAULT_DISPLAY_CONFIGURATION;
          const attributes = safeObjGet(attributesBySeriesId[id]);

          return renderTooltip(title, value, displayConfiguration, attributes);
        },
      },
    }));
  }, [
    attributesBySeriesId,
    chartConfig.chartDisplay,
    colorBySeriesId,
    displayConfigurationBySeriesId,
    filteredSeries,
    seriesNameById,
  ]);

  const options = useMemo((): AgPolarChartOptions => {
    if (data == null || displayConfigurationBySeriesId == null) {
      return {
        width,
        height,
      };
    }

    return {
      data: isLoading ? [] : data,
      series,
      tooltip: {
        class: CHART_TOOLTIP_CLASSNAME,
        position: {
          type: 'pointer',
        },
      },
      legend: {
        enabled:
          typeof chartIndex === 'number'
            ? false
            : (chartDisplay.legend?.showLegend ?? chartDisplay.series.length > 1),
        reverseOrder: true,
        position: 'right',
        preventHidingAll: true,
        item: {
          maxWidth: 200,
          label: {
            formatter: ({ itemId, value }) => {
              const seriesId = String(itemId);
              const attributes = safeObjGet(attributesBySeriesId[seriesId]);

              if (attributes != null && attributes.length > 0) {
                let base = value;
                for (const attr of attributes) {
                  base += ` (${attr.value})`;
                }
                return base;
              }

              return value;
            },
          },
        },
      },
      background: {
        visible: false,
      },
      width,
      height,
    };
  }, [
    data,
    displayConfigurationBySeriesId,
    isLoading,
    series,
    chartIndex,
    chartDisplay.legend?.showLegend,
    chartDisplay.series.length,
    width,
    height,
    attributesBySeriesId,
  ]);

  return <AgChart isLoading={isLoading} options={options} />;
};

export default React.memo(AgNightingaleChart);
