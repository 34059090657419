import {
  AgHierarchyChartOptions,
  AgTreemapSeriesOptions,
  AgTreemapSeriesTooltipRendererParams,
} from 'ag-charts-community';
import React, { useMemo } from 'react';

import AgChart from 'components/AgGridComponents/AgChart/AgChart';
import { safeObjGet } from 'helpers/typescript';
import useBlockContext from 'hooks/useBlockContext';
import { DEFAULT_DISPLAY_CONFIGURATION } from 'reduxStore/models/value';

import { renderTooltip } from './agChartTooltips';
import { AgChartProps, CHART_TOOLTIP_CLASSNAME } from './agCharts';
import { useChartAggregatedData, useChartConfig } from './chartHooks';

const AgTreemapChart: React.FC<AgChartProps> = ({ chartIndex, chartDisplay, driverIds }) => {
  const { blockId } = useBlockContext();
  const {
    attributesBySeriesId,
    dateRange,
    displayConfigurationBySeriesId,
    height,
    seriesNameById,
    width,
  } = useChartConfig(blockId, chartDisplay);

  const filteredSeries = useMemo(() => {
    if (chartIndex != null) {
      const series = chartDisplay.series.find((s) => s.driverId === driverIds[chartIndex]);
      if (series) {
        return [series];
      }
    }
    return chartDisplay.series;
  }, [chartIndex, chartDisplay.series, driverIds]);

  const { isLoading, data: ungroupedData } = useChartAggregatedData(
    dateRange,
    filteredSeries,
    chartDisplay.groups,
  );

  const data = useMemo(() => {
    if (!ungroupedData) {
      return [];
    }

    return chartDisplay.groups.map((group, index) => {
      const seriesForGroup = chartDisplay.series.filter((series) => {
        return group.seriesIds.includes(series.id);
      });

      const groupedData = ungroupedData.filter(({ id }) => {
        return seriesForGroup.some((series) => series.id === id);
      });

      return {
        title: group.name ?? `Group ${index + 1}`,
        children: groupedData.map(({ id, value }) => {
          let title = safeObjGet(seriesNameById[id]);
          const attributes = attributesBySeriesId[id];

          if (attributes != null && attributes.length > 0) {
            for (const attr of attributes) {
              title += ` (${attr.value})`;
            }
          }

          return {
            id,
            title,
            total: value,
          };
        }),
      };
    });
  }, [
    attributesBySeriesId,
    chartDisplay.groups,
    chartDisplay.series,
    seriesNameById,
    ungroupedData,
  ]);

  const series = useMemo((): AgTreemapSeriesOptions[] => {
    return [
      {
        type: 'treemap',
        labelKey: 'title',
        sizeKey: 'total',
        sizeName: 'Total',
        highlightStyle: {
          series: {
            enabled: true,
            dimOpacity: 0.5,
          },
        },
        tooltip: {
          enabled: true,
          showArrow: false,

          renderer: ({
            datum,
          }: AgTreemapSeriesTooltipRendererParams<
            { title: string; children: unknown[] } | { id: string; title: string; total: number }
          >) => {
            if ('children' in datum) {
              return datum.title;
            }

            const id = datum.id;
            const key = safeObjGet(seriesNameById[id]);
            const value = datum.total;
            const displayConfiguration =
              displayConfigurationBySeriesId[datum.id] ?? DEFAULT_DISPLAY_CONFIGURATION;
            const attributes = safeObjGet(attributesBySeriesId[datum.id]);
            return renderTooltip(key, value, displayConfiguration, attributes);
          },
        },
      },
    ];
  }, [attributesBySeriesId, displayConfigurationBySeriesId, seriesNameById]);

  const options = useMemo((): AgHierarchyChartOptions => {
    if (displayConfigurationBySeriesId == null) {
      return {
        width,
        height,
      };
    }

    return {
      data: isLoading ? [] : data,
      series,
      tooltip: {
        class: CHART_TOOLTIP_CLASSNAME,
        position: {
          type: 'pointer',
        },
      },
      legend: {
        enabled: chartDisplay.legend?.showLegend ?? chartDisplay.series.length > 1,
      },
      background: {
        visible: false,
      },
      width,
      height,
    };
  }, [
    displayConfigurationBySeriesId,
    isLoading,
    data,
    series,
    chartDisplay.legend?.showLegend,
    chartDisplay.series.length,
    width,
    height,
  ]);

  return <AgChart isLoading={isLoading} options={options} />;
};

export default React.memo(AgTreemapChart);
