import {
  AgPieSeriesTooltipRendererParams,
  AgPolarChartOptions,
  AgPolarSeriesOptions,
} from 'ag-charts-community';
import React, { useMemo } from 'react';

import AgChart from 'components/AgGridComponents/AgChart/AgChart';
import { safeObjGet } from 'helpers/typescript';
import useBlockContext from 'hooks/useBlockContext';
import { DEFAULT_DISPLAY_CONFIGURATION } from 'reduxStore/models/value';

import { AgChartProps, CHART_TOOLTIP_CLASSNAME } from './agCharts';
import { renderTooltip } from './agChartTooltips';
import { useChartAggregatedData, useChartConfig } from './chartHooks';

interface AgDonutChartDatum {
  color: string;
  id: string;
  label: string;
  value: number;
}

const OTHER_ID = 'other';
const OTHER_COLOR = '#d9d9d9';

const AgDonutChart: React.FC<AgChartProps> = ({ chartIndex, chartDisplay }) => {
  const { blockId } = useBlockContext();
  const {
    attributesBySeriesId,
    colorBySeriesId,
    dateRange,
    displayConfigurationBySeriesId,
    height,
    seriesNameById,
    width,
  } = useChartConfig(blockId, chartDisplay);

  const { isLoading, data: aggregatedData } = useChartAggregatedData(
    dateRange,
    chartDisplay.series,
    chartDisplay.groups,
  );

  const data = useMemo((): AgDonutChartDatum[] | null => {
    if (!aggregatedData) {
      return null;
    }

    return aggregatedData.map(({ id, value }) => {
      let label = safeObjGet(seriesNameById[id]) ?? '';
      const attributes = safeObjGet(attributesBySeriesId[id]);
      if (attributes != null && attributes.length > 0) {
        for (const attr of attributes) {
          label += ` (${attr.value})`;
        }
      }

      return {
        color: colorBySeriesId[id] ?? '',
        id,
        label,
        value,
      };
    });
  }, [aggregatedData, seriesNameById, attributesBySeriesId, colorBySeriesId]);

  const filteredData = useMemo((): AgDonutChartDatum[] | null => {
    if (data == null || typeof chartIndex !== 'number') {
      return data;
    }

    const dataToDisplay = data.filter((_, i) => i === chartIndex);
    const dataToAggregate = data.filter((_, i) => i !== chartIndex);
    const aggregatedValue = dataToAggregate.reduce(
      (acc, { value }) => (typeof value === 'number' ? acc + value : acc),
      0,
    );

    return [
      {
        color: OTHER_COLOR,
        id: OTHER_ID,
        label: '',
        value: aggregatedValue,
      },
      ...dataToDisplay,
    ];
  }, [data, chartIndex]);

  const series = useMemo((): AgPolarSeriesOptions[] => {
    const config: AgPolarSeriesOptions = {
      type: 'donut',
      angleKey: 'value',
      calloutLabelKey: 'label',
      innerRadiusRatio: 0.7,
      fills: filteredData?.map(({ color }) => color) ?? [],
      highlightStyle: {
        series: {
          enabled: true,
          dimOpacity: 0.5,
        },
      },
      sectorLabel: {
        color: 'white',
        fontWeight: 'bold',
      },
      tooltip: {
        enabled: true,
        showArrow: false,
        renderer: ({ datum }: AgPieSeriesTooltipRendererParams<AgDonutChartDatum>) => {
          const id = String(datum.id);

          if (id === OTHER_ID) {
            return '';
          }

          const displayConfiguration =
            displayConfigurationBySeriesId[datum.id] ?? DEFAULT_DISPLAY_CONFIGURATION;

          return renderTooltip(datum.label, datum.value, displayConfiguration, []);
        },
      },
    };

    return [config];
  }, [displayConfigurationBySeriesId, filteredData]);

  const options: AgPolarChartOptions = useMemo(() => {
    if (filteredData == null || displayConfigurationBySeriesId == null) {
      return {
        width,
        height,
      };
    }

    return {
      data: isLoading ? [] : filteredData,
      series,
      tooltip: {
        class: CHART_TOOLTIP_CLASSNAME,
        position: {
          type: 'pointer',
          label: {
            enabled: chartDisplay.series.every((s) => s.showLabels),
          },
        },
      },
      legend: {
        enabled:
          typeof chartIndex === 'number'
            ? false
            : (chartDisplay.legend?.showLegend ?? chartDisplay.series.length > 1),
        reverseOrder: true,
        position: 'right',
        preventHidingAll: true,
        item: {
          maxWidth: 200,
        },
      },
      background: {
        visible: false,
      },
      width,
      height,
    };
  }, [
    filteredData,
    displayConfigurationBySeriesId,
    isLoading,
    series,
    chartDisplay.series,
    chartDisplay.legend?.showLegend,
    chartIndex,
    width,
    height,
  ]);

  return <AgChart isLoading={isLoading} options={options} />;
};

export default React.memo(AgDonutChart);
